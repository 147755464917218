import type * as Types from '../../../typings/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
type CreateQuizResultMutationVariables = Types.Exact<{
  input: Types.CreateQuizResultInput;
}>;


type CreateQuizResultMutationPayload = { __typename?: 'Mutation', createQuizResult: { __typename?: 'CreateQuizResultPayload', error: { __typename?: 'SecurityError' } | { __typename?: 'ServerError', message: string, serverErrorCode: Types.ServerErrorCode } | null } };


 const CreateQuizResultDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateQuizResult"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateQuizResultInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createQuizResult"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ServerError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"serverErrorCode"},"name":{"kind":"Name","value":"errorCode"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]}}]}}]} as unknown as DocumentNode<CreateQuizResultMutationPayload, CreateQuizResultMutationVariables>;


export type CreateQuizResultVariables = CreateQuizResultMutationVariables;
export type CreateQuizResultPayload = CreateQuizResultMutationPayload;

// remove fragments duplicates
// @ts-expect-error
CreateQuizResultDocument['definitions'] = [ ...new Set(CreateQuizResultDocument.definitions) ];

export default CreateQuizResultDocument;